import { Pipe, PipeTransform } from '@angular/core';

import { Collection } from 'src/app/shared/models';

@Pipe({
	name: 'giftBudgetLimitFilter',
	standalone: true,
})
export class GiftBudgetLimitFilterPipe implements PipeTransform {
	transform(collections: Collection[], price: number): Collection[] {
		if (!price) {
			return collections;
		}

		return collections.filter(c => c.price === price);
	}
}
